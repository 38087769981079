import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-d3183c0e"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "map-wrap w-full h-full bg-white" }
const _hoisted_2 = {
  class: "map w-full h-full mix-blend-difference",
  ref: "mapContainer"
}

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, null, 512)
  ]))
}