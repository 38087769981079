
import { Map, Marker} from 'maplibre-gl';
import { shallowRef, onMounted, onUnmounted, markRaw } from 'vue';

export default {
  name: "Map",
  props: {
    payload: Object,
    institutions: Array
  },
  setup () {

    const mapContainer = shallowRef(null);
    const map = shallowRef(null);
    onMounted(() => {
      const initialState = { lng: 19.753, lat: 79.6844, zoom: 0 };
      map.value = markRaw(new Map({
        container: mapContainer.value,
        style: `https://api.maptiler.com/maps/toner/style.json?key=Tpl1j0sPTcWpdwpftq1q`,
        center: [initialState.lng, initialState.lat],
        zoom: initialState.zoom,
        maxZoom: 7,
        maxBounds:  [[-180, -80], [180, 80]]
      }));



    });
    onUnmounted(() => {
      map.value.remove();
    });

    return {
      map, mapContainer
    };
  },

    mounted() {
      var geojson  = []
      console.log(this.payload)
      if(!this.payload) return
      for (var i = 0; i < this.payload.length; i++) {
        console.log(this.payload)

        var obj = {
          type: 'Feature',
          id: this.payload[i].id,
          'geometry': {
            'type': 'Point',
            'coordinates': [this.payload[i].allocation.physical[0].lng, this.payload[i].allocation.physical[0].lat],
          }
        }
        geojson.push(obj);
      }

      //console.log(geojson)


      let _this = this
      geojson.forEach(function (marker) {
      // create a DOM element for the marker
        var el = document.createElement('div');

        el.className = 'marker';
        el.style.width = '15px';
        el.style.height = '15px';
        el.style.background = '#00e7ff';
        el.style.filter = 'contrast(10)'
        el.style.borderRadius= '100%';
        el.style.cursor= 'pointer';

      //  console.log(marker.id)
        el.addEventListener('click', function () {
          _this.$router.push({ name: 'Institution', params: { id: marker.id } })
        });

        // add marker to map
        new Marker(el)
        .setLngLat(marker.geometry.coordinates)
        .addTo(_this.map);
      });

    }
};
