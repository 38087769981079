

export default {
  computed: {
    payload() {
      return this.$store.state.handlePayload.payload
    },
    institutions() {
      return this.$store.state.handleInstitutions.payload
    }
  },
  mounted() {
  }
}
